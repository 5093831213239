<template>
  <div class="table-sales col-md-12 col-lg-12 col-sm-12">
    <div class="table-container">
      <div class="table-header">
        <p>{{ title }}</p>
      </div>
      <div class="table-section">
        <div class="table-title">
          <p v-for="(label, idx) in label" :key="idx">{{ label }}</p>
        </div>
        <div class="table-content" >
          <div class="item-table oper" v-for="(item, idx) in dataPush" :key="idx">
            <p><a :href="item.url" target='_blank'>{{ item.url }}</a></p>
            <p>{{ item.totalAndroid }}</p>
            <p>{{ item.totalApple }}</p>
          </div>
        </div>
        <div class="table-total">
          <p>TOTAL </p>
          <p style="font-weight: bold; font-size: 15px;">{{ totalColumn('totalAndroid')}}</p>
          <p style="font-weight: bold; font-size: 15px;">{{ totalColumn('totalApple')}}</p>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataPush', 'title', 'label'],
  name: 'PushTable',
  data () {
    return {
    }
  },
  mounted () {
    
  },
  methods: {
   totalColumn (column) {
      return this.dataPush.reduce((total, consultant) => total + consultant[column], 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  overflow: hidden;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  .table-header {
    height: 3.5rem;
    border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    display: flex;
    align-items: center;
    p {
      margin: 0 0 0 25px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .table-section {
    height: 322.5px;
    display: flex;
    flex-direction: column;
    .table-title {
      background: #f5f7fb;
      height: 3.5rem;
      border-bottom: 1px solid rgba(0, 40, 100, 0.12);
      display: flex;
      align-items: center;
      justify-content: space-around;
      p {
        width: 100px;
        margin: 0;
        font-size: 12px;
        text-transform: capitalize;
      }
      p:nth-child(1) {
        width: 80%;

        color: #4981cc;
        font-weight: bold;
        text-align: left;
        padding-left: 10px;
      }
    }
    .table-content {
      display: flex;
      flex-direction: column;
      height: 229px;
      overflow-y: auto;
      .item-table {
        padding: 7px 0;
        max-height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        p {
          width: 100px;
          font-size: 12px;
          margin: 0;
        }
        p:nth-child(1) {
          width: 80%;
          overflow:auto;
          text-align: left;
          padding-left: 10px;
        }
      }
      .goal {
        p {
          font-size: 11px;
        }
      }
      .item-table:nth-child(odd) {
        background-color:#ffffff;
        border-bottom: 1px solid #4982cc25;
      }
      .item-table:nth-child(even) {
        background-color:#4982cc1c;
      }
    }
    .table-total {
      background: #4981cc;
      color: #ffffff;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      p {
        font-size: 12px;
        margin: 0;
        width: 100px;
      }
      p:nth-child(1) {
        width: 80%;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        padding-left: 10px;
      }
    }
  }
}

@media (max-width: 500px) {
  .item-table.corp {
    p:nth-child(1) {
      padding-left: 3px !important;
      width: 255px !important;
    }
    p:nth-child(2) {
      font-size: 11px !important;
    }
    p {
      width: 90px !important;
    }
  }

  .table-title {
    p {
      font-size: 9px !important;
    }
  }

  .table-total {
    p {
      font-size: 11px !important;
    }
   p:nth-child(2) {
      margin-right: 5px !important;
    }
  }

  .item-table.oper {
    p {
      font-size: 10px !important;
    }
    p:nth-child(1) {
      padding-left: 3px !important;
      width: 265px !important;
    }
    p:nth-child(2) {
      margin-right: 5px !important;
    }
  }
}
</style>