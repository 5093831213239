<template>
<div :key="iddiv">
  <div>
    <div class="my-3 my-md-5">
      <div class="container" id="dashboard" style="position: relative;">
        <b-alert
          style="position: absolute;width: 100%;"
          :show="dismissCountDown"
          fade
          dismissible
          variant="warning"
          @dismissed="dismissCountDown=0"
        >
          {{alertMessage}}
        </b-alert>
        <div class="page-header">
          <h1 class="page-title">
            Dashboard - Empresas Portal Corporativo
          </h1>
        </div>

        <b-input-group size="sm" class="col-12 col-sm-12 p-0 pb-4">
          <b-input-group-prepend is-text class="firstLabel">
            Empresa:
          </b-input-group-prepend>
          <div style="min-width: 270px;background: white;height: 34px;">
            <v-select
          @input="search()"
          v-model="dashboard.companies.selected"
          :options="dashboard.companies.list"
          :reduce="opt => opt.code"
          :clearable="false"
          :get-option-label="(option) => option.name"></v-select>
          </div>
          <b-form-checkbox id="showEmpresasInativas" style="margin-left: 15px;"
              v-model="showEmpresasInativas" @input="getCompanies()">
            <span>Exibir empresas inativas</span>
          </b-form-checkbox>
        </b-input-group>

        <div class="row">
          <form name="day">
            <div class="col-12 form-group filter-day">
              <div class="selectgroup selectgroup-pills">
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="all" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('all');">Todos os Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="thirty" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('thirty');">30 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="fifteen" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('fifteen');">15 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="seven" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('seven');">7 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="yesterday" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('yesterday');">Ontem</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="today" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('today');">Hoje</span>
              </label>
              <label class="selectgroup-itx'em">
                <HotelDatePicker class="item-date-picker" format="DD-MM-YYYY" @confirm="customDateConfirmed()" v-model="customDate" type="date" :clearable="false" confirm range placeholder="Selecionar data customizada"></HotelDatePicker>
              </label>
              </div>

            </div>
          </form>
        </div>
        <hr class="mt-0 pt-0">

        <template>
          <div class="row row-cards">
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'QTDE DE ACESSOS'" :value="dashboard.access" :size="'large'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'QTDE DE CADASTROS'" :value="dashboard.signUps" :size="'large'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'QTDE DE COMPRAS'" :value="dashboard.purchases" :size="'large'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'QTDE DE INGRESSOS'" :value="dashboard.tickets" :percentage="''" :size="'large'" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'QTDE TOTAL DE DOWNLOADS'" :value="dashboard.downloadsApp" :percentage="''" :size="'large'" :status="''" :label="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'DOWNLOADS ANDROID'" :value="dashboard.downloadsAppAndroid" :percentage="''" :size="'large'" :status="''" :label="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'DOWNLOADS APLLE'" :value="dashboard.downloadsAppApple" :percentage="''" :size="'large'" :status="''" :label="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'QTD DE FUNCIONÁRIO'" :value="dashboard.employees" :percentage="''" :size="'large'" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'CADASTRO GERAL'" :value="dashboard.generalRegister" :percentage="''" :size="'large'" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="isLoaded" :title="'PERCENTUAL DE CADASTRADO'" :value="dashboard.percentRegister" :percentage="''" :size="'large'" :status="''" :label="'%'"></card-info>
          </div>
          <div class="row row-cards" style="display: flex; gap: 13px; margin: 20px 0; display: flex; align-items: flex-start;">
            <sales-table-card :label="['EMPRESA', 'CIDADE', 'QTDE PEDIDOS']" :title="'VENDAS POR CIDADE POR PEDIDO'" :dataSalesForCity="dashboard.salesForCity" :status="'corp'" :key="key"/>
            <half-circle-spinner v-if="!isLoaded" :size="60" color="#ddd"></half-circle-spinner>
            <TableRefCorporative  v-else :base="dataTableRef" />
          </div>
          <div class="row row-cards" style="display: flex; gap: 13px; margin: 20px 0; display: flex; align-items: flex-start;">
            <push-list :label="['URL', 'Total App Android', 'Total App Apple']" :title="'Abertura Push Aplicativos'" :dataPush="dashboard.pushPhone" />
            <half-circle-spinner v-if="!isLoaded" :size="60" color="#ddd"></half-circle-spinner>
          </div>
          <half-circle-spinner v-if="!isLoaded" :size="60" color="#ddd"></half-circle-spinner>
          <div v-else class="row">
            <overtime-chart :key="'timetable_'" :title="'Mês a Mês'" :dados="overtimeData"></overtime-chart>
          </div>
        </template>
      </div>
    </div>

  </div>
</div>
</template>

<script>
// muitas funções não são utilizadas ainda (está com dados mockados!!!)
import { SemipolarSpinner } from 'epic-spinners'
import VueResource from "vue-resource";
import config from "@/config";
import Vue from "vue";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import TableRefCorporative from '../TableRefCorporative.vue';
import cardInfo from "@/views/dashboard/new-card-info";
import pieChart from "@/views/dashboard/pie-chart";
import pieChartWithFilter from "@/views/dashboard/pie-chart-with-filter";
import overtimeChart from "@/views/dashboard/overtime-chart";
import HotelDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { HollowDotsSpinner } from 'epic-spinners'
import { HalfCircleSpinner } from 'epic-spinners'
import SalesTableCard from '../sales-table-card.vue';
import PushList from '../push-list.vue';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { func } from "@/functions";
import { userService } from '../../../components/common/services/user';
import { eventService } from "../../../components/common/services/event";
import { dashboardService } from "../../../components/common/services/dashboard";
Vue.component('v-select', vSelect)
const moment = extendMoment(Moment);

export default {
  name: 'DashboardCorporativo',
  mixins: [func],
  components: {
    TableRefCorporative,
    cardInfo,
    pieChart,
    overtimeChart,
    pieChartWithFilter,
    HotelDatePicker,
    HollowDotsSpinner,
    HalfCircleSpinner,
    SalesTableCard,
    PushList
  },
  data() {
    return {
      key: 0,
      isLoaded: false,
      datepickerHidden: true,
      overtimeData: [],
      customDate: '',
      showEmpresasInativas: false,
      datepicker: {
        id: 1,
        format: 'DD/MM/YYYY',
        minNights: 0,
        maxNights: 60,
        hoveringTooltip: true,
        displayClearButton: true,
        startDate: new Date('2003-01-01'),
        endDate: new Date('2055-01-01'),
        startingDateValue: new Date(),
        ptBr: {
          night: 'Dia',
          nights: 'Dias',
          'day-names': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          'check-in': 'Início',
          'check-out': 'Fim',
          'month-names': ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        },
      },
      iddiv: 1,
      processing: false,
      form: {
        searchtype: 'today',
        id_base: '',
        id_evento: '',
        date: '',
        hour: '',
        selectedDate: {
          start: moment(new Date()).format("DD/MM/YYYY"),
          end: '',
        }
      },
      selects: {
        base: [],
        events: [],
        days: [],
        hours: []
      },
      alertMessage: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      dashboard: {
        companies: {
          selected: '',
          list: []
        },
        access: 0,
        signUps: 0,
        purchases: 0,
        downloadsApp: 0,
        downloadsAppAndroid: 0,
        downloadsAppApple: 0,
        tickets: 0,
        employees: 0,
        generalRegister: 0,
        percentRegister: 0,
        salesForCity: [],
        pushPhone:[],
        bychannel: {
          loaded: false,
          result: [],
          key: {
            id: 1
          }
        },
        bypaymenttype: {
          loaded: false,
          result: [],
          key: {
            id: 1
          }
        },
        values: {
          loaded: false,
          total_sold: '',
          total_soldamountformatted: '',
          averageticket_formatted: '',
          typeofdiff: '',
          typeofdiffAmount: '',
          per_total_diff_formatted: '',
          perAmount_total_formatted: '',
          key: {
            total_sold: 1,
            total_soldamountformatted: 1,
            averageticket_formatted: 1,
          },
        },
      },
      dataTableRef: [
        {
          title: 'Download do Aplicativo',
          data: [
            { dias: 30, porcentagem: 10, meta: 100, realizado: 30, falta: -70 },
            { dias: 60, porcentagem: 30, meta: 300, realizado: 220, falta: -26.67 },
            { dias: 90, porcentagem: 50, meta: 500, realizado: 420, falta: -16 },
          ]
        },
        {
          title: 'Aquisição de Ingressos',
          data: [
            { dias: 30, porcentagem: 1, meta: 10, realizado: 2, falta: -80 },
            { dias: 60, porcentagem: 3, meta: 30, realizado: 3, falta: -90 },
            { dias: 90, porcentagem: 5, meta: 50, realizado: 4, falta: -92 },
          ]
        },
        {
          title: 'Cadastros',
          data: [
            { dias: 30, porcentagem: 1, meta: 10, realizado: 4, falta: -60 },
            { dias: 60, porcentagem: 3, meta: 30, realizado: 5, falta: -83.33 },
            { dias: 90, porcentagem: 5, meta: 50, realizado: 6, falta: -88 },
          ]
        },
        {
          title: 'Acessos',
          data: [
            { dias: 30, porcentagem: 10, meta: 100, realizado: 30, falta: -70 },
            { dias: 60, porcentagem: 30, meta: 300, realizado: 220, falta: -26.67 },
            { dias: 90, porcentagem: 50, meta: 500, realizado: 420, falta: -16 },
          ]
        },
        {
          title: 'Vendas',
          data: [
            { dias: 30, porcentagem: 10, meta: 500, realizado: 250, falta: 50 },
            { dias: 60, porcentagem: 10, meta: 500, realizado: 250, falta: 50 },
            { dias: 90, porcentagem: 10, meta: 500, realizado: 250, falta: 50 },
          ]
        }
      ]
    }
  },
  computed: {},
  created() {
    this.hideWaitAboveAll();
    // this.closer();
    //  this.$refs.dtpicker.hideDatepicker();
  },
  async mounted () {
    await this.getCompanies();
  },
  methods: {
    async getCompanies() {
      this.dashboard.companies.selected = ''
      const listOfCompanies = await dashboardService.getCompanies(this.getLoggedId(), this.showEmpresasInativas)
      if (listOfCompanies && listOfCompanies.empresas) {
        this.dashboard.companies.list = listOfCompanies.empresas.sort((a,b) => a.name.localeCompare(b.name))
      this.dashboard.companies.list.unshift({code: 'TODOS', name: 'TODAS'})
        if (this.dashboard.companies.list.length > 0 ) {
          if(listOfCompanies.todas==1){
            this.dashboard.companies.selected = 'TODOS'
          }else{
            this.dashboard.companies.selected =  this.dashboard.companies.list[0].code
          }
          // await this.getDashboard()
          await this.search('today')
        }
      }
    },
    async customDateConfirmed() {
      if (this.customDate.length > 1 && this.customDate[0]) {
        this.form.searchtype = ''
        if (moment(this.customDate[0]).isValid()) {
          this.form.selectedDate.start = moment(this.customDate[0]).format("DD-MM-YYYY")
        }
        if (moment(this.customDate[1]).isValid()) {
          this.form.selectedDate.end = moment(this.customDate[1]).format("DD-MM-YYYY")
        }
        await this.getDashboard()
      }
    },
    async getDashboard () {
      this.overtimeData = []
      this.isLoaded = false;
      const finded = this.dashboard.companies.list.filter(item => item.code === this.dashboard.companies.selected)

      if (finded.length > 0) {
        const filter = {
          code: finded[0].code,
          name: finded[0].name,
          start: this.form.selectedDate.start,
          end: this.form.selectedDate.end
        }

        if (!filter.start && !filter.end) {
          this.form.searchtype = 'today'
        }

        const getSalesForCity = await dashboardService.getSalesForCity(filter)
        if (getSalesForCity && getSalesForCity.dados) {
          this.dashboard.salesForCity = getSalesForCity.dados
          this.key++
        }
        
        const getPush = await dashboardService.getPush(filter)
        if (getPush && getPush.dados) {
          this.dashboard.pushPhone = getPush.dados
        
        }



        const getEmployees = await dashboardService.getEmployees(filter)
        if (getEmployees && getEmployees.dados) {
          this.dashboard.employees = getEmployees.dados.total || 0
          this.dashboard.generalRegister = getEmployees.debug.cadastroGeral.resultadoCadastroGeral.total || 0
          const percent = ((getEmployees.debug.cadastroGeral.resultadoCadastroGeral.total / getEmployees.dados.total) * 100).toFixed(2)
          if (percent === 'Infinity' || percent === Infinity || percent === 'NaN') {
            this.dashboard.percentRegister = 0
          } else {
            this.dashboard.percentRegister = parseFloat(percent)
          }
        }

        const res = await dashboardService.getAccess(this.getLoggedId(), filter)
        this.dashboard.access = 0
        this.dashboard.signUps = 0
        this.dashboard.purchases = 0
        this.dashboard.downloadsApp = 0
        this.dashboard.downloadsAppAndroid = 0
        this.dashboard.downloadsAppApple = 0
        this.dashboard.tickets = 0
        if (res && res.status === 'error') {
          this.isLoaded = true;
          return this.showAlert('Nenhuma Informação Encontrada')
        }
        if (res && res.dados) {
          this.dashboard.access = res.dados.acesso.total
          this.dashboard.signUps = res.dados.cadastro.total
          this.dashboard.purchases = res.dados.vendas.total
          this.dashboard.downloadsApp = res.dados.downloadsapps.total
          this.dashboard.downloadsAppAndroid = res.dados.downloadsapps.totalAndroid
          this.dashboard.downloadsAppApple = res.dados.downloadsapps.totalApple

          this.dashboard.tickets = res.dados.ingressos.total
          if (res.dados.datas && res.dados.datas.length > 0) {
            const dateToShow = res.dados.datas.map(item => {
              const dateBeforeParse = new Date(item.date)
              return dateBeforeParse.toLocaleDateString("pt-BR").replace(/([/])/g, '-')
            })
            this.overtimeData = [
              ['x', ...dateToShow],
              ["QTDE DE ACESSOS", ...res.dados.acesso.values],
              ["QTDE DE CADASTROS", ...res.dados.cadastro.values],
              ["QTDE DE COMPRAS", ...res.dados.vendas.values],
              ["QTDE DE INGRESSOS", ...res.dados.ingressos.values],
            ]
          }
        }
        const periodsToFind = [30, 60, 90]
        const corporateByCustomPeriods = await Promise.all(periodsToFind.map(period => {
          const filterCopy = {...filter}
          filterCopy.start = moment(filter.start, "DD-MM-YYYY").subtract(period, "days").format("DD-MM-YYYY")
          filterCopy.end = filter.end || filter.start
          return dashboardService.getAccess(this.getLoggedId(), filterCopy)
        }))
        if (corporateByCustomPeriods && corporateByCustomPeriods.length) {
          corporateByCustomPeriods.forEach((corporatePeriod, idx) => {
            if (corporatePeriod && corporatePeriod.dados) {
              this.dataTableRef.forEach(tableData => {
                tableData.data[idx].meta = this.dashboard.employees ? (this.dashboard.employees * tableData.data[idx].porcentagem) / 100 : 0
                if (tableData.title === 'Acessos') {
                  tableData.data[idx].realizado = corporatePeriod.dados.acesso.total
                }
                if (tableData.title === 'Cadastros') {
                  tableData.data[idx].realizado = corporatePeriod.dados.cadastro.total
                }
                if (tableData.title === 'Aquisição de Ingressos') {
                  tableData.data[idx].realizado = corporatePeriod.dados.ingressos.total

                }
                if (tableData.title === 'Download do Aplicativo') {
                  tableData.data[idx].realizado = corporatePeriod.dados.downloadsapps.total
                }
                tableData.data[idx].falta = ((tableData.data[idx].meta - tableData.data[idx].realizado) / tableData.data[idx].meta) * 100
              })
            }
          })
        }
      }
      this.isLoaded = true;
    },
    showAlert (message) {
      this.alertMessage = message
      this.dismissCountDown = this.dismissSecs
    },
    async startchanged(date) {
      this.isLoaded = false
      this.form.selectedDate.start = moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : '';
      await this.getDashboard()
      this.isLoaded = true
      if (date) {
        this.form.searchtype = ''
      }
      // this.search('');
      // this.checkifpersonalized();
    },
    async endchanged(date) {
      this.isLoaded = false
      this.form.selectedDate.end = moment(date).isValid() ? moment(date).add(1,'days').format("DD/MM/YYYY") : '';
      await this.getDashboard()
      this.isLoaded = true
      if (date) {
        this.form.searchtype = ''
      }
      // this.search('');
      // this.checkifpersonalized();
    },
    checkifpersonalized() {
      // debugger;
      Vue.nextTick().then(response => {
        if (this.form.selectedDate.start != '' && this.form.selectedDate.end != '') {
          // this.form.searchtype = '';
          this.search('');
        }
        else {
          if (this.form.selectedDate.start == '' && this.form.selectedDate.end == '' && this.form.searchtype == '') {
            this.search('today');
          }
        }
      });
    },
    toggleDate() {
      this.$refs.dtpicker.hideDatepicker();
      this.$refs.dtpicker.toggleDatepicker();
    },
    closer() {
      dashboardService.closer(this.getLoggedId()).then(
        response => {
          // this.hideWaitAboveAll();
          if (this.validateJSON(response)) {
            if (response != null) {
              this.form.id_base = response.id_base;
              this.form.id_evento = response.id_evento;
              this.form.date = response.date;
              this.form.hour = response.hour;
            }
          }
          Vue.nextTick().then(response => {
            this.populateBases();
          });
        },
        error => {
          // this.hideWaitAboveAll();
          this.populateBases();
        }
      );
    },
    populateBases() {
      if (this.getLoggedId() == null || this.getLoggedId() == "") return;

      userService.baseSelect(this.getLoggedId()).then(
        response => {
          this.hideWaitAboveAll();

          if (this.validateJSON(response)) {
            this.selects.base = response;
            if (response.length == 1) {
              this.form.id_base = response[0].value;
              this.populateEvents();
            } else {
              if (this.form.id_base != '') {
                Vue.nextTick().then(response => {
                  this.populateEvents();
                });
              }
            }
          }
        },
        error => {
          this.toastError("Falha na execução.");
        }
      );
    },
    populateEvents() {

      if (this.form.id_base == '') {
        return;
      }
      this.selects.events = [];
      this.selects.days = [];
      this.selects.hours = [];

      eventService.select(this.getLoggedId(), this.form.id_base).then(
        response => {
          this.selects.events = response;
          if (response.length == 1) {
            this.form.id_evento = response[0].value;
            this.populateDays();
          } else {
            if (this.form.date != '') {
              Vue.nextTick().then(response => {
                this.populateDays();
              });
            }
          }
        },
        error => {
          // this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    populateDays() {
      if (this.form.id_base == '' || this.form.id_evento == '') {
        return;
      }
      this.selects.days = [];
      this.selects.hours = [];

      eventService.selectDays(this.getLoggedId(), this.form.id_base, this.form.id_evento).then(
        response => {
          this.selects.days = response;
          if (response.length == 1) {
            this.form.date = response[0].value;
            this.populateHours();
          } else {
            if (this.form.hour != '') {
              Vue.nextTick().then(response => {
                this.populateHours();
              });
            }
          }
        },
        error => {
          this.toastError("Falha na execução.");
        }
      );
    },
    populateHours() {
      if (this.form.id_base == '' || this.form.id_evento == '' || this.form.date == '') {
        return;
      }
      this.selects.hours = [];

      eventService.selectDayHours(this.getLoggedId(), this.form.id_base, this.form.id_evento, this.form.date).then(
        response => {
          this.selects.hours = response;
          if (response.length == 1) {
            this.form.hour = response[0].value;
            this.search('today');
          } else {
            if (this.form.hour != '') {
              Vue.nextTick().then(response => {
                this.search('today');
              });
            }
          }
          // this.hideWaitAboveAll();
        },
        error => {
          this.toastError("Falha na execução.");
        }
      );
    },
    clear() {
      this.dashboard.values.loaded = false;
      this.dashboard.boletos.loaded = false;
      this.dashboard.occupation.loaded = false;
      this.dashboard.bychannel.loaded = false;
      this.dashboard.timetable.loaded = false;
    },
    selBase() {
      Vue.nextTick().then(response => {
        this.selects.events = [];
        this.selects.days = [];
        this.selects.hours = [];

        this.form.id_evento = "";
        this.form.date = "";
        this.form.hour = "";

        this.populateEvents();
      });
    },
    selEvent() {
      Vue.nextTick().then(response => {
        this.selects.days = [];
        this.selects.hours = [];
        this.clear();

        this.form.date = "";
        this.form.hour = "";

        this.populateDays();
      });
    },
    selDays() {
      Vue.nextTick().then(response => {
        this.selects.hours = [];
        this.clear();

        this.form.hour = "";

        this.populateHours();
      });
    },
    selHours() {
      Vue.nextTick().then(response => {
        this.clear();
        this.search("today");
      });
    },
    async search(type) {
      this.isLoaded = false
      if (!type && this.customDate) {
        await this.getDashboard() // mantém o filtro custom ao trocar de empresa
        return false
      }
      this.form.searchtype = type ? type : this.form.searchtype
      let startDate = ''
      this.customDate = ''
      let endDate = moment()
      this.form.selectedDate.start = ''
      this.form.selectedDate.end = ''
      switch (this.form.searchtype) {
        case 'fifteen':
            startDate = moment().subtract(15, "days")
          break
        case 'seven':
            startDate = moment().subtract(7, "days")
          break
        case 'yesterday':
            startDate = moment().subtract(1, "days")
            endDate = ''
          break
        case 'thirty':
            startDate = moment().subtract(30, "days")
          break
        case 'today':
            startDate = moment()
            endDate = ''
          break
        case 'all':
            startDate = moment(new Date('2021-01-01'))
            endDate = moment()
          break
        case '':
            startDate = ''
            endDate = ''
          break
      }

      if (startDate) {
        this.form.selectedDate.start =  startDate.format("DD-MM-YYYY")
      }
      if (endDate) {
        this.form.selectedDate.end = endDate.format("DD-MM-YYYY")
      }
      await this.getDashboard()
      this.isLoaded = true
    },
  }
}
</script>

<style lang="scss">
#dashboard {
  .item-date-picker {
    .mx-input { 
      height: 28px;
    }
  }
  .vs__dropdown-toggle {
    border: 1px solid #dfdfdf;
    border-radius: 1px;
  }
  .datepicker__dummy-wrapper {
    border-radius: 12px;
    height: 26px !important;

    .datepicker__input {
      font-size: 14px;
      color: rgb(154, 160, 172);
      font-size: 11px;
      color: #9aa0ac;
      line-height: 2.2;
      height: 26px;
    }

    .datepicker__clear-button {
      margin: 8px -2px 0 0;
      font-size: 14px;
      height: 22px !important;
    }

    .datepicker__input:first-child {
      background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgOCAxOCI+CiAgICA8cGF0aCBmaWxsPSIjOTU5OUFBIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0uMTE5LjcxOGw3LjE1OCA3LjQwNy0uMDMzLS41NTEtNi43MzcgOC44ODlhLjQyNS40MjUgMCAwIDAgLjA4LjU5My40Mi40MiAwIDAgMCAuNTktLjA4bDYuNzM3LTguODg5YS40MjUuNDI1IDAgMCAwLS4wMzMtLjU1MUwuNzIzLjEyOEEuNDIuNDIgMCAwIDAgLjEyOC4xMmEuNDI1LjQyNSAwIDAgMC0uMDA5LjU5OHoiLz4KPC9zdmc+Cg==) no-repeat 100%/5px !important;
    }
  }

  .datepicker__wrapper {
    background: transparent url('/assets/icons/edit.svg') no-repeat 14px/13px !important;
    height: 22px !important;
  }

  .datepicker__clear-button svg {
    width: 10px !important;
    top: -10px !important;
  }

  @media (max-width: 1270px) {

  .input-group-prepend, .input-group-append, .input-group-btn, .input-group-text {
    margin-bottom: 2px;
    margin-top: 2px;
    font-weight: bold;
    padding-left: 0;
    background: transparent;
    border: none;
  }

  .input-group {
    display: block;
  }

  select {
    border-radius: 4px;
    width: 50%;
    display: flex;
  }
  }

    .input-group-prepend, .input-group-append, .input-group-btn, .input-group-text { 
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-weight: bold;
    color: hsl(0, 0%, 13%);

    }
  select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: hsl(0,0%,29%);
  }


}
</style>
