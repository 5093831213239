<template>
  <div class="container-table-corp">
    <div class="header-table-corp">
      <p class="header-title">TABELA DE REFERÊNCIA - PROGRAMA CORPORATIVO</p>
    </div>
    <div class="table-corp">
      <div class="row-table-corp" v-for="(row, idx) in base" :key="idx">
        <div class="row-title">
          <p class="title-item-table">{{ row.title }}</p>
          <p class="title-item-table">%</p>
          <p class="title-item-table">META</p>
          <p class="title-item-table">REALIZADO</p>
          <p class="title-item-table">FALTA</p>
        </div>
        <div class="row-items-corp" v-for="(item, idx) in row.data" :key="idx">
          <p class="label-item-table">{{ item.dias }} dias</p>
          <p class="label-item-table">{{ formatNumber(item.porcentagem) }}%</p>
          <p class="label-item-table">{{ formatNumber(item.meta) }}</p>
          <p class="label-item-table">{{ formatNumber(item.realizado) }}</p>
          <p class="label-item-table">{{ formatNumber(item.falta, 5) }}%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableRefCorporative',
  props: ['base'],
  components: {},
  data () {
    return {}
  },
  mounted () {},
  computed: {
    currency () {
      return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    },
  },
  methods: {
    formatNumber (value, maximum = 10) {
      return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: maximum }).format(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-table-corp {
  width: 575px;
  height: 440px;
  background: #ffffff;
  border: 1px solid rgba(0, 40, 100, 0.12);
  .header-table-corp {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    .header-title {
      color: #454548;
      font-weight: bold;
      margin: 0;
    }
  }
  .table-corp {
    width: 100%;
    height: 340px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .row-table-corp {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 40, 100, 0.12);
      .row-title {
        width: 100%;
        padding: 5px;
        background: #f5f7fb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-item-table:first-child {
          width: 25%;
        }
        .title-item-table {
          width: 18.75%;
          margin: 0;
          font-weight: bold;
          color: #4981cc;
          font-size: 12px;
        }
      }
      .row-items-corp:nth-child(odd) {
        background: #4982cc1c;
      }
      .row-items-corp {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label-item-table:first-child {
          width: 25%;
        }
        .label-item-table {
          width: 18.75%;
          font-size: 11px;
          color: #454548;
          margin: 0;
        }
      }
    }
    .row-table-corp:last-child {
      border: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container-table-corp {
    height: 470px;
    .header-table-corp {
    .header-title {
      font-size: 12px;
    }
  }
  }
}
</style>